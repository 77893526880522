import { gql } from '@apollo/client';

const getBasket = gql`
  query getBasket($basketId: UUID!) {
    getBasket(id: $basketId) {
      id
      step
      createdAt
      updatedAt
      activity {
        id
        slug
        name
        description
        ageRange
        ageMonthsStart
        ageMonthsEnd
        cloudinaryImageId
        blockSubtype
        subscriptionTrialType
        subscriptionTrialSessionCount
        subscriptionTrialPrice
        subscriptionOffDates
        subscriptionStart
        activityType
        isOnline
        ponchoPayAccepted
        hideEndTimes
        supplier {
          id
          name
          links {
            url
            name
          }
          additionalInformationCaptureEnabled
        }
        location {
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        ageMonthsStart
        ageMonthsEnd
        socialProof {
          limitedSpots
          popular
        }
      }
      classes {
        id
        name
      }
      tickets {
        id
        ticket
        ticketName
        ticketPrice
        ticketCapacity
        attendees {
          id
          fullName
          birthDate
          isAdult
        }
        sessions {
          id
          date
          dayOfTheWeek
          startTime
          endTime
        }
        subscriptionTrialSelected
        subscriptionTrialEndTimestamp
        subscriptionOption {
          id
          schedule {
            id
            startTime
            endTime
            capacity
            dayOfTheWeek
          }
          scheduleSummary
          ticketPrice
          stripeProductId
          stripePriceId
        }
        amount
        pricingPolicy
      }
      guardian {
        id
        children {
          id
          fullName
          birthDate
          isAdult
        }
      }
      guest {
        id
        fullName
        email
        phoneNumber
        isDataConsentGiven
        marketingOptIn
        children {
          id
          fullName
          birthDate
          isAdult
        }
      }
      addons {
        attendee {
          id
          fullName
        }
        perSession {
          addonOption {
            id
            name
            description
            price
            availableSessions {
              id
              startTime
              endTime
              date
            }
          }
          note
          selectedSessions {
            id
            startTime
            endTime
            date
          }
        }
        perBooking {
          addonOption {
            id
            name
            description
            price
          }
          note
          quantity
        }
      }
      hasAddons
      product {
        dateRange
        weekdays
        startTime
        endTime
        timeGroup
        spotsLeft
        checkoutKey
        checkoutValue
        checksum
        singleSessionDate
        blockLastSessionDate
        blockFirstSessionDate
        numberOfSessionsAvailable
        subscriptionNextSessionDate
        allBlockDates {
          date
          isInPast
          startTime
          endTime
          spotsLeft
        }
        multipleSessionDates
      }
      originalAmount
      discount
      finalAmount
      promotion {
        code
      }
      isMarketingConsentGiven
      supplierLinksAccepted
      supplierMarketingConsentGiven
      questionAnswers {
        id
        requiredBeforeCheckout
        question {
          id
          type
          title
          isPerAttendee
          shortTitle
        }
        attendeeAnswers {
          answer
          attendeeId
          previousAnswer
        }
        bookerAnswer
        previousBookerAnswer
      }
    }
  }
`;

export default getBasket;
