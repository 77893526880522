import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { ActivityDetails, ClassesType, Product, SelectedTickets } from 'types';
import { ActivityTicket } from 'interfaces';
import { useDisclosure } from '@mantine/hooks';

interface IBasketContext {
  basketItems: Product[];
  selectedSession: Product | null;
  setSelectedSession: Dispatch<SetStateAction<Product | null>>;
  setBasketItems: Dispatch<SetStateAction<Product[]>>;
  activityDetails?: ActivityDetails | null;
  setActivityDetails: Dispatch<SetStateAction<ActivityDetails | null>>;
  selectedTickets?: SelectedTickets[];
  setSelectedTickets: Dispatch<SetStateAction<SelectedTickets[]>>;
  userToken?: string;
  setUserToken: Dispatch<SetStateAction<string>>;
  showTicketTypesModal?: boolean;
  setShowTicketTypesModal: Dispatch<SetStateAction<boolean>>;
  showCreateAccountModal?: boolean;
  setShowCreateAccountModal: Dispatch<SetStateAction<boolean>>;
  maximumTicketQuantity?: number;
  basketActivityTickets?: ActivityTicket[];
  setBasketActivityTickets: Dispatch<SetStateAction<ActivityTicket[]>>;
  addToBasket: (item: Product) => void;
  removeFromBasket: (date: string) => void;
  basketPopupOpened: boolean;
  toggleBasketPopup: () => void;
  selectedClass: ClassesType | null;
  setSelectedClass: Dispatch<SetStateAction<ClassesType | null>>;
}

const BasketContext = createContext<IBasketContext | undefined>(undefined);

export const useBasket = () => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error('useBasket must be used within a BasketProvider');
  }
  return context;
};

export const BasketProvider: React.FC<{
  initialState?: IBasketContext;
  children?: React.ReactNode;
}> = ({ children, initialState }) => {
  const [activityDetails, setActivityDetails] = useState<ActivityDetails | null>(
    initialState?.activityDetails || null,
  );

  const [selectedClass, setSelectedClass] = useState<ClassesType | null>(null);

  // for Block Bookings
  const [selectedSession, setSelectedSession] = useState<Product | null>(null);

  const [basketItems, setBasketItems] = useState<Product[]>(initialState?.basketItems || []);

  const [basketActivityTickets, setBasketActivityTickets] = useState<ActivityTicket[]>(
    initialState?.basketActivityTickets || [],
  );

  const [selectedTickets, setSelectedTickets] = useState<SelectedTickets[]>(
    initialState?.selectedTickets || [],
  );

  const [userToken, setUserToken] = useState(initialState?.userToken || '');

  const [showTicketTypesModal, setShowTicketTypesModal] = useState(
    initialState?.showTicketTypesModal || false,
  );

  const [showCreateAccountModal, setShowCreateAccountModal] = useState<boolean>(
    initialState?.showCreateAccountModal || false,
  );

  const [opened, { toggle, open }] = useDisclosure(false);

  // Maximum tickets that can be added to the basket, determined by the session with the least "spots left".
  const [maximumTicketQuantity, setMaximumTicketQuantity] = useState<number>(
    initialState?.maximumTicketQuantity || 0,
  );

  const addToBasket =
    initialState?.addToBasket ||
    ((item: Product) => {
      if (basketItems.some((basketItem) => basketItem.checkoutValue === item.checkoutValue)) return;

      setBasketItems((currentItems) => [...currentItems, item]);

      open();
    });

  const removeFromBasket =
    initialState?.removeFromBasket ||
    ((checkoutValue: string) => {
      setBasketItems((currentItems) =>
        currentItems.filter((item) => item.checkoutValue !== checkoutValue),
      );
    });

  //   Handle updating the maximum ticket quantity when the basket items change
  useEffect(() => {
    const minSpotsLeft = Math.min(...basketItems.map((item) => item.spotsLeft));

    setMaximumTicketQuantity(minSpotsLeft);
  }, [basketItems]);

  return (
    <BasketContext.Provider
      value={{
        basketItems,
        selectedSession,
        setSelectedSession,
        setBasketItems,
        addToBasket,
        removeFromBasket,
        activityDetails,
        setActivityDetails,
        selectedTickets,
        setSelectedTickets,
        userToken,
        setUserToken,
        showTicketTypesModal,
        setShowTicketTypesModal,
        showCreateAccountModal,
        setShowCreateAccountModal,
        maximumTicketQuantity,
        basketActivityTickets,
        setBasketActivityTickets,
        basketPopupOpened: opened,
        toggleBasketPopup: toggle,
        selectedClass,
        setSelectedClass,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
