import { gql } from '@apollo/client';

const GetBookingBySession = gql`
  query getBookingBySession($checkoutSessionId: String!) {
    activityBookingByCheckoutSessionId(checkoutSessionId: $checkoutSessionId) {
      id
      checkoutSessionId
      basketId
      originalAmount
      promotion {
        code
      }
      subscriptionNextPayment
      activity {
        id
        name
        isOnline
        supplier {
          id
          name
          slug
        }
        location {
          id
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        activityType
        subscriptionStart
        hideEndTimes
      }
      guardianId
      guestId
      finalAmount
      bookingType
      status
      numberOfBookedTickets
      promotion {
        code
      }
      bookedTickets {
        ticketNameAtBooking
        ticketPriceAtBooking
        ticket {
          id
          name
          price
          ticketType
        }
        numberOfSessions
        subscriptionTrialPrice
        subscriptionTrialSessionCount
        subscriptionStartTime
        subscriptionEndTime
        subscriptionDayOfWeek
        subscriptionOffDates
        subscriptionTrialSelected
        bookingAddons {
          name
          price
          quantity
        }
        sessions {
          id
          sessionStart
          sessionEnd
          status
          addons {
            name
            price
          }
        }
        firstSession {
          firstSessionDate
        }
        firstSessionToBe
      }
    }
  }
`;

export default GetBookingBySession;
