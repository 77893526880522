import { Radio, RadioGroupProps, RadioProps } from '@mantine/core';
import classes from './PebbleRadio.module.scss';
import classNames from 'classnames';

type IPebbleRadioGroupProps = RadioGroupProps & {
  apply_filter_styling?: string;
};

type IPebbleRadioProps = RadioProps & {
  apply_filter_styling?: string;
  boldLabelFont?: boolean;
  applySearchBarStyling?: boolean;
};

export const PebbleRadioGroup: React.FC<IPebbleRadioGroupProps> = (props) => {
  return (
    <Radio.Group
      classNames={{
        label: props.apply_filter_styling === 'true' ? classes.radioGroupLabelFilters : '',
      }}
      {...props}
    >
      {props.children}
    </Radio.Group>
  );
};

export const PebbleRadio: React.FC<IPebbleRadioProps> = ({
  boldLabelFont = true,
  applySearchBarStyling = false,
  ...props
}) => {
  return (
    <Radio
      {...props}
      classNames={{
        body: classNames(classes.radioBody, {
          [classes.filtersRadioBody]: props.apply_filter_styling === 'true',
        }),
        label: classNames(classes.radioLabel, {
          [classes.boldLabelFont]: boldLabelFont,
          [classes.filtersRadioLabel]: applySearchBarStyling,
        }),
        radio: classes.radio,
        root: classes.radioRoot,
        labelWrapper: classNames(classes.labelWrapper, {
          [classes.desktopFilterLabelWrapper]: applySearchBarStyling,
        }),
      }}
    />
  );
};
