import { gql } from '@apollo/client';

const GetBookingByBasketId = gql`
  query getBookingByBasketId($basketId: UUID!) {
    activityBookingByBasketId(basketId: $basketId) {
      id
      checkoutSessionId
      basketId
      originalAmount
      promotion {
        code
      }
      subscriptionNextPayment
      activity {
        id
        name
        isOnline
        supplier {
          id
          name
          slug
        }
        location {
          id
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        activityType
        hideEndTimes
      }
      guardianId
      guestId
      finalAmount
      bookingType
      status
      numberOfBookedTickets
      bookedTickets {
        ticketNameAtBooking
        ticket {
          id
          name
          price
          ticketType
        }
        numberOfSessions
        subscriptionStartTime
        subscriptionEndTime
        subscriptionDayOfWeek
        subscriptionOffDates
        subscriptionTrialSelected
        bookingAddons {
          name
          price
          quantity
        }
        sessions {
          id
          sessionStart
          sessionEnd
          status
          addons {
            name
            price
          }
        }
      }
    }
  }
`;

export default GetBookingByBasketId;
