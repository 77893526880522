import { gql } from '@apollo/client';

const GetActivityBySlugQuery = gql`
  query marketplaceActivityBySlug($activitySlug: String!) {
    marketplaceActivityBySlug(activitySlug: $activitySlug) {
      activity {
        id
        slug
        slugChangeCount
        seoPageTitle
        seoPageDescription
        name
        description
        ageRange
        ageMonthsStart
        ageMonthsEnd
        activityType
        bookingType
        supplierToProvide
        guardianToProvide
        cloudinaryImageId
        additionalImageIds
        categories {
          id
          name
        }
        allowBlockBookings
        allowIndividualBookings
        blockSubtype
        hideEndTimes
        socialProof {
          limitedSpots
          popular
          justAdded
        }
        isOnline
        onlinePublicInfo
        locationName
        location {
          addressLine1
          addressLine2
          city
          postCode
          country
          latitude
          longitude
        }
        supplier {
          id
          slug
          name
          website
          phoneNumber
          isClaimed
          aboutUs
          ageRange
          ageMonthsStart
          ageMonthsEnd
          cloudinaryImageId
          facebook
          instagram
          twitter
          reviewsEnabled
          links {
            name
            url
          }
          activitiesLocation
          location {
            city
            postCode
          }
          seoPageTitle
          franchise {
            slug
            name
          }
        }
        email
        phoneNumber
        enquiryBookingUrl
        showEmail
        showPhoneNumber
        showEnquiryBookingUrl
        expressEnquiry
        ponchoPayAccepted
        ofstedUrn
        hideEndTimes
        subscriptionOffDates
        subscriptionTrialType
        subscriptionTrialSessionCount
        subscriptionTrialPrice
        subscriptionInfo
        subscriptionStart
      }
      eligibleChildren {
        id
      }
      dateRange
      weekdays
      classes {
        id
        name
        dateRange
        weekdays
        tickets {
          block {
            id
            name
            description
            price
            capacity
            order
            isDeleted
            isAvailable
            pricingPolicy
          }
          individual {
            id
            name
            description
            price
            capacity
            order
            isDeleted
            isAvailable
            pricingPolicy
          }
        }
        addons {
          name
          description
          price
          isPublished
          addonType
        }
        sessions {
          date
          products {
            dateRange
            weekdays
            startTime
            endTime
            timeGroup
            spotsLeft
            numberOfSessionsAvailable
            checkoutKey
            checkoutValue
            singleSessionDate
            checksum
          }
        }
        blocks {
          dateRange
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          numberOfSessionsAvailable
          checkoutKey
          checkoutValue
          checksum
          allBlockDates {
            date
            isInPast
            startTime
            endTime
            spotsLeft
          }
        }
      }
      subscriptions {
        products {
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          checkoutKey
          checkoutValue
          subscriptionNextSessionDate
        }
        tickets {
          id
          name
          description
          price
          ticketType
          capacity
          isAvailable
          pricingPolicy
        }
      }
      totalActivityBookings
      lastBookingTime
      anySpotsLeft
    }
  }
`;

export default GetActivityBySlugQuery;
